.veriff-container {
  width: 100% !important;
}

.veriff-submit {
  padding: 1rem !important;
  width: 100% !important;
  height: auto !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  border-radius: 2rem !important;
  background: #0a33ff !important;
}

.veriff-description {
  display: none !important;
}
